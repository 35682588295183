// Here you can add other styles
.temperatureDiv {
  width: 100%;
  height: 500px;
}
.leaflet-container {
  width: 100%;
  height: 80vh;
}
.c-app {
  background-color: #fff;
}
.c-app .temperaturesTable tr th,
.c-app .temperaturesTable tr td {
  border: 1px solid #000;
  color: #000;
  font-weight: 600;
}
.tablePositions {
  width: 750px;
  padding: 10px;
}
.leaflet-popup-content-wrapper {
  width: 790px;
}
/*
 * Globals
 */

input.form-control,
select.form-control,
textarea.form-control {
  color: #000 !important;
}
.form-control::placeholder {
  color: rgb(201, 197, 197);
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}

/*
 * Base structure
 */

.cover-container {
  max-width: 42em;
}

.cursor-pointer {
  cursor: pointer;
}

/*
 * Header
 */

.nav-masthead .nav-link {
  padding: 0.25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

.device-create .custom-control-label {
  place-content: flex-start;
}
.device-create .custom-control-label svg {
  margin-left: 10px;
}

.c-sidebar-brand.d-md-down-none {
  background-color: #fff;
  border-right: 1px solid #000;
}

.c-header {
  justify-content: space-between;
}

.col-xxl,
.col-xxl-auto,
.col-xxl-12,
.col-xxl-11,
.col-xxl-10,
.col-xxl-9,
.col-xxl-8,
.col-xxl-7,
.col-xxl-6,
.col-xxl-5,
.col-xxl-4,
.col-xxl-3,
.col-xxl-2,
.col-xxl-1,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-left: 5px;
  padding-right: 5px;
}

.fw-600 {
  font-weight: 600;
  cursor: pointer;
}
.modal-header-custom {
  font-size: 20px;
  color: #fff;
  background-color: #191078;
  min-width: 500px;
}
.modal-header-custom .close {
  color: #fff;
}
.modal-body-custom {
  font-size: 15px;
  min-width: 480px;
  max-height: 300px;
  overflow-y: auto;
  color: #191078;
  background-color: #fff;
}
.modal-footer-custom {
  min-width: 500px;
}
// @media (width: 576px)
// {
//   .modal-dialog {
//     max-width: 1000px;
//   }
//   .modal-content {
//     align-items: center;
//   }
// }
.cursor-pointer:hover {
  cursor: pointer;
}

.card-temperature {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 10px;
}

.container-temperature-circle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.item-temperature-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
}

.label-temperature-circle {
  margin-top: 10px;
  font-weight: 700;
}

.name-temperature-circle {
  margin-top: 2px;
  font-weight: 700;
  font-size: 12px;
}

.temperature-circle {
  height: 60px;
  width: 60px;
  background-color: #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
}

.temperature-subtitle-circle {
  height: 16px;
  width: 16px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.temperature-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover {
  min-width: 180px;
}

.absolute-list {
  position: absolute;
  z-index: 999;
  overflow-y: scroll;
  max-height: 450px;
  height: 250px;
}

.fade {
  opacity: 1;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items > .c-sidebar-nav-dropdown {
  background-color: #343a40;
}
.c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items >  .c-sidebar-nav-item {
  background-color: #505357;
}
// .c-sidebar-nav-dropdown-items .c-sidebar-nav-item {
//   padding-left: 15px;
// }
.open-input tspan {
  font-size: 17px;
  color: red;
}
.dropdown-container,
.rmsc .dropdown-heading,
.dropdown-heading-value,
.dropdown-heading-value span {
  max-height: 35px;
  line-height: 35px;
}
.dropdown-heading-value {
  display: flex;
  align-items: center;
}
